<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 135">
        <g id="Capa_2" data-name="Capa 2">
            <g id="Graphic_Elements" data-name="Graphic Elements">
                <circle class="cls-1" cx="67.5" cy="67.5" r="67.5"/>
                <path class="cls-2"
                      d="M67.5,32.66c11.35,0,12.69,0,17.17.25a23.4,23.4,0,0,1,7.9,1.46,14.11,14.11,0,0,1,8.06,8.06,23.4,23.4,0,0,1,1.46,7.9c.21,4.48.25,5.82.25,17.17s0,12.69-.25,17.17a23.4,23.4,0,0,1-1.46,7.9,14.11,14.11,0,0,1-8.06,8.06,23.4,23.4,0,0,1-7.9,1.46c-4.48.21-5.82.25-17.17.25s-12.69,0-17.17-.25a23.4,23.4,0,0,1-7.9-1.46,14.11,14.11,0,0,1-8.06-8.06,23.4,23.4,0,0,1-1.46-7.9c-.21-4.48-.25-5.82-.25-17.17s0-12.69.25-17.17a23.4,23.4,0,0,1,1.46-7.9,14.11,14.11,0,0,1,8.06-8.06,23.4,23.4,0,0,1,7.9-1.46c4.48-.21,5.82-.25,17.17-.25m0-7.66c-11.54,0-13,.05-17.52.26a31,31,0,0,0-10.32,2A21.7,21.7,0,0,0,27.23,39.66a31,31,0,0,0-2,10.32C25.05,54.51,25,56,25,67.5s.05,13,.26,17.52a31,31,0,0,0,2,10.32,21.7,21.7,0,0,0,12.43,12.43,31,31,0,0,0,10.32,2c4.53.21,6,.26,17.52.26s13,0,17.52-.26a31,31,0,0,0,10.32-2,21.7,21.7,0,0,0,12.43-12.43,31,31,0,0,0,2-10.32c.21-4.53.26-6,.26-17.52s0-13-.26-17.52a31,31,0,0,0-2-10.32A21.7,21.7,0,0,0,95.34,27.23a31,31,0,0,0-10.32-2C80.49,25.05,79,25,67.5,25Z"/>
                <path class="cls-2"
                      d="M67.5,45.68A21.82,21.82,0,1,0,89.32,67.5,21.82,21.82,0,0,0,67.5,45.68Zm0,36A14.17,14.17,0,1,1,81.67,67.5,14.18,14.18,0,0,1,67.5,81.67Z"/>
                <circle class="cls-2" cx="90.19" cy="44.81" r="5.1"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'InstagramLogo',
    }
</script>

<style scoped>
    .cls-1 {
        fill: #fff;
    }

    .cls-2 {
        fill: #9f3944;
    }
</style>
