<template>
  <div class="h-100">
    <WebLayout v-if="$route.meta.layout === 'web-layout' || $route.meta.layout === undefined">
      <router-view/>
    </WebLayout>
  </div>
  <FooterHome/>
</template>

<style lang="scss" rel="stylesheet/scss"></style>

<script>

import WebLayout from "@/views/layouts/WebLayout";
import FooterHome from "@/partials/FooterHome";

export default {
  components: {
    FooterHome,
    WebLayout,
  },

  data() {
    return {
      checkAuth: false
    };
  },

  created() {
    fetch('https://api.sethotels.net/api/analytics/5fe34bc9-e3bd-4195-9d18-d3357882a2c0', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({url: window.location.href, referrer_url: document.referrer, title: document.title})
    });
  }
}
</script>
