<template>
  <svg height="100%" viewBox="0 0 247 320" version="1.1" xmlns="http://www.w3.org/2000/svg"
       style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="Capa_2"><g id="Longo"><path d="M239.054,83.049l-0,4.36c0.02,0.072 0.02,0.149 -0,0.22l-0,0.18c-0.01,0.259 -0.04,0.517 -0.09,0.77c-0,0.42 -0,0.84 -0.09,1.25c-2.141,23.878 -10.877,46.698 -25.23,65.9c-0.099,0.178 -0.22,0.342 -0.36,0.49c-4.251,5.299 -8.966,10.209 -14.09,14.67l-3.23,0.09l-121.319,4.8c-1.723,-0.028 -3.353,-0.798 -4.47,-2.11l-60.74,-64.09l-0.13,-0.12l0,-0.14c-0.119,-0.104 -0.226,-0.222 -0.32,-0.35c-0.62,-2.38 -1.11,-4.81 -1.6,-7.23c-0.64,-3.18 -1.12,-6.42 -1.45,-9.74c0.012,-0.167 -0.015,-0.335 -0.08,-0.49c-0.064,-0.154 -0.09,0.09 -0.14,0.09l0,-2.25c0.02,-0.041 0.02,-0.088 0,-0.13l0,-3.37c0,-3.68 0,-7.319 0.18,-10.949c0.09,-4.13 0.27,-8.25 0.49,-12.34c0.41,-1.581 1.061,-3.089 1.93,-4.47c1.44,-2.38 3.41,-3.81 5.59,-3.81l217.279,-0c1.996,0.119 3.819,1.187 4.9,2.87c0.963,1.26 1.716,2.668 2.23,4.17l-0,0.09c0.4,6.7 0.68,13.51 0.73,20.429c0.02,0.4 0.02,0.81 0.01,1.21Z" style="fill:#9f3944;fill-opacity:0.5;fill-rule:nonzero;"/><ellipse
      cx="186.244" cy="20.25" rx="20.81" ry="20.25" style="fill:#9f3944;"/><path
      d="M5.645,91.589c-0.02,0.237 -0.02,0.474 0,0.71c-0.05,-0.22 -0.08,-0.444 -0.09,-0.67l0.09,-0.04Z"
      style="fill:#9f3944;fill-rule:nonzero;"/><path
      d="M238.454,93.089c-0.4,4 -0.95,7.91 -1.72,11.78c-4.99,26.214 -18.47,50.07 -38.35,67.87c-18.071,16.314 -40.888,26.438 -65.109,28.89c-1.63,0.13 -3.35,0.27 -5,0.36c-1,-0 -2,0.09 -3.14,0.09l-3.14,-0c-1.54,-0 -3.09,-0 -4.63,-0.13c-1.002,0.021 -2.004,-0.022 -3,-0.13c-1.46,-0.11 -2.91,-0.19 -4.36,-0.33c-1.69,-0.19 -3.35,-0.37 -5,-0.65l-0.24,-0c-24.948,-3.867 -47.941,-15.844 -65.41,-34.07c-1.12,-1.2 -2.24,-2.39 -3.36,-3.63c-1.12,-1.24 -2.14,-2.49 -3.18,-3.73c0,-0.09 -0.14,-0.14 -0.19,-0.23c-0.101,-0.152 -0.222,-0.29 -0.36,-0.41c-10.913,-14.333 -18.809,-30.731 -23.21,-48.2c-0.131,-0.433 -0.238,-0.874 -0.32,-1.32c-0.64,-2.5 -1.18,-5 -1.64,-7.47c-0.666,-3.26 -1.15,-6.566 -1.45,-9.92c0.015,-0.178 -0.016,-0.357 -0.09,-0.52c-0.09,-0.19 0,0 0.09,0c0.28,-0.22 0.63,-0.41 1.11,-0.74l0.36,-0.22c11.17,-7.13 58.46,-32.999 112.33,-1.12l0.37,0.23c1.36,0.78 2.77,1.71 4.13,2.58c59.379,44.53 106.699,7.78 114.509,1.02Z"
      style="fill:#9f3944;fill-rule:nonzero;"/><path
      d="M239.054,89.829c0.02,0.072 0.02,0.148 -0,0.22c-0.01,0.309 -0.04,0.616 -0.09,0.92c0.015,0.27 -0.015,0.541 -0.09,0.8c-2.162,23.532 -10.907,45.985 -25.23,64.78c-0.102,0.173 -0.223,0.334 -0.36,0.48c-4.658,6.104 -9.892,11.747 -15.63,16.85c-17.643,15.789 -39.793,25.667 -63.329,28.24c-1.43,0.12 -2.92,0.21 -4.36,0.34c-1,0.09 -2.05,0.18 -3.14,0.18c-1.09,-0 -2,0.09 -3.09,0.09l-2.07,-0c-1.88,-0 -3.77,-0 -5.58,-0.13c-1.81,-0.13 -3.42,-0.32 -5.07,-0.44c-31.37,-3.16 -59.16,-19.37 -78.28,-43.46c0,-0.08 -0.13,-0.12 -0.18,-0.21c-0.101,-0.149 -0.222,-0.283 -0.36,-0.4c-10.756,-13.668 -18.567,-29.416 -22.94,-46.25c-0.13,-0.44 -0.22,-0.84 -0.31,-1.28c-0.63,-2.33 -1.12,-4.72 -1.61,-7.1c-0.642,-3.15 -1.123,-6.33 -1.44,-9.53c0.015,-0.165 -0.016,-0.331 -0.09,-0.48c-0.09,-0.45 -0.09,-0.92 -0.16,-1.45l0,-0.52l1.39,1.12l0.37,0.26c11.52,9.4 55.77,39.53 110.8,-1.11l0.36,-0.26c0.595,-0.372 1.163,-0.786 1.7,-1.24c58.549,-38.739 110.379,-6.25 118.379,-0.72c0.09,0.09 0.19,0.09 0.28,0.18c0.09,0.09 0.13,0.08 0.13,0.12Z"
      style="fill:#9f3944;fill-opacity:0.6;fill-rule:nonzero;"/><g><g><path d="M9.545,259.508l3.18,-21.999c0.25,-1.77 0.12,-3 -0.38,-3.71c-0.555,-0.725 -1.439,-1.124 -2.35,-1.06l-0.83,-0l0,45.999l-9.16,0l0,-52.999l13.78,-0c2.92,-0 5.127,0.706 6.62,2.12c1.528,1.486 2.345,3.56 2.24,5.69c-0.005,1.489 -0.234,2.969 -0.68,4.39c-0.541,1.665 -1.21,3.287 -2,4.85l-11.18,23.909l-0.76,-7.19l1.52,0Z" style="fill:#9f3944;fill-rule:nonzero;"/><path
      d="M26.725,276.768c-1.87,-1.77 -2.8,-4.26 -2.8,-7.49l0,-43.519l9.23,-0l0,44.429c-0.023,0.527 0.136,1.047 0.45,1.47c0.269,0.359 0.692,0.571 1.14,0.57l0.07,0c0.455,0.023 0.895,-0.174 1.18,-0.53c0.299,-0.444 0.443,-0.975 0.41,-1.51l0,-44.429l9.24,-0l0,43.519c0,3.23 -0.94,5.72 -2.8,7.49c-1.86,1.77 -4.52,2.65 -8,2.65l-0.2,0c-3.43,0 -6.06,-0.88 -7.92,-2.65Z"
      style="fill:#9f3944;fill-rule:nonzero;"/><path
      d="M61.385,225.759l9.31,-0l-0,52.999l-9.84,0l-4.84,-38.219l-0.61,-0l2.12,38.219l-9.39,0l0,-52.999l9.92,-0l4.69,38.599l0.68,0l-2.04,-38.599Z"
      style="fill:#9f3944;fill-rule:nonzero;"/></g>
    <g><path d="M72.425,225.759l19.6,-0l-0,7.64l-19.6,-0l-0,-7.64Zm14.38,52.999l-9.23,0l-0,-46.569l9.23,-0l-0,46.569Z" style="fill:#9f3944;fill-rule:nonzero;"/></g>
    <g><path d="M100.505,277.678c-1.256,1.039 -2.84,1.603 -4.47,1.59c-1.55,0.046 -3.054,-0.543 -4.16,-1.63c-1.113,-1.08 -1.67,-3.036 -1.67,-5.87c0.017,-1.605 0.13,-3.208 0.34,-4.8c0.227,-1.793 0.467,-3.523 0.72,-5.19l0.53,-3.1l4.77,-32.919l11.58,-0l6.36,52.999l-9.61,0l-0.84,-6.74l-0.53,0c-0.7,2.72 -1.707,4.607 -3.02,5.66Zm0.11,-7.08c0.356,0.392 0.871,0.602 1.4,0.57c0.504,0.024 0.988,-0.205 1.29,-0.61c0.33,-0.542 0.461,-1.182 0.37,-1.81l-3.7,-36.859l-0.61,-0l0.68,36.629c-0.024,0.739 0.186,1.467 0.6,2.08l-0.03,0Z" style="fill:#9f3944;fill-rule:nonzero;"/>
      <path d="M142.885,225.759l9.309,-0l0,52.999l-9.839,0l-4.85,-38.219l-0.6,-0l2.12,38.219l-9.38,0l-0,-52.999l9.92,-0l4.69,38.599l0.68,0l-2.05,-38.599Z"
            style="fill:#9f3944;fill-rule:nonzero;"/>
      <path d="M154.914,225.759l19.37,-0l0,7.64l-10,-0l0,37.849l10,0l0,7.49l-19.37,0l0,-52.979Zm5.22,22.699l13,0l0,5.38l-13,0l0,-5.38Z"
            style="fill:#9f3944;fill-rule:nonzero;"/>
      <path d="M177.954,227.499c1.687,-1.707 4.097,-2.564 7.23,-2.57c1.646,-0.099 3.283,0.31 4.69,1.17c1.364,1.038 2.564,2.275 3.56,3.67l3.79,4.92l-0.16,12.999l-11.8,-15.289l-0.46,-0l0,38.899l-9.38,1.7l0,-38.159c0,-3.18 0.84,-5.62 2.53,-7.34Zm19.19,20.429l0,30.81l-8.93,0l0,-6.89l-0.3,0c-0.952,2.124 -2.242,4.079 -3.82,5.79c-0.992,0.946 -2.309,1.476 -3.68,1.48c-0.809,-0.002 -1.608,-0.183 -2.34,-0.53c-0.817,-0.414 -1.479,-1.081 -1.89,-1.9c-0.562,-1.149 -0.824,-2.422 -0.76,-3.7l9.38,-4.92c-0.021,0.666 0.153,1.323 0.5,1.89c0.27,0.472 0.777,0.76 1.32,0.75c0.473,-0.009 0.907,-0.268 1.14,-0.68c0.32,-0.522 0.477,-1.128 0.45,-1.74l0,-20.36l8.93,0Z"
            style="fill:#9f3944;fill-rule:nonzero;"/></g>
    <g><path d="M208.344,278.738l-9.16,0l0,-52.999l9.08,-0l0.08,52.999Zm11.31,-50.999c1.512,1.38 2.333,3.364 2.24,5.41c-0.009,1.602 -0.35,3.185 -1,4.65c-0.69,1.608 -1.621,3.101 -2.76,4.43l-8.1,9.609l-1.43,0.08l3.4,-14.079c0.46,-1.72 0.39,-3 -0.19,-3.82c-0.598,-0.836 -1.584,-1.309 -2.61,-1.25l-2.12,-0l1.14,-7l5.14,-0c2.269,-0.142 4.511,0.572 6.28,2l0.01,-0.03Zm-2.76,20.259l0.38,0l5,30.73l-9.63,0l-2.8,-18.92c-0.1,-0.91 -0.22,-1.77 -0.38,-2.58c-0.072,-0.827 -0.195,-1.648 -0.37,-2.46c-0.16,-0.63 -0.33,-0.94 -0.53,-0.94l-0.76,0l0,-2.27l2.84,0l6.25,-3.56Z" style="fill:#9f3944;fill-rule:nonzero;"/></g>
    <g><path d="M232.864,277.678c-1.253,1.039 -2.833,1.602 -4.46,1.59c-1.553,0.047 -3.06,-0.542 -4.17,-1.63c-1.113,-1.08 -1.667,-3.036 -1.66,-5.87c0.012,-1.605 0.125,-3.208 0.34,-4.8c0.23,-1.79 0.46,-3.52 0.72,-5.19l0.53,-3.1l4.77,-32.919l11.58,-0l6.35,52.999l-9.61,0l-0.83,-6.74l-0.53,0c-0.707,2.72 -1.717,4.607 -3.03,5.66Zm0.11,-7.08c0.357,0.391 0.872,0.601 1.4,0.57c0.505,0.028 0.991,-0.202 1.29,-0.61c0.334,-0.54 0.469,-1.181 0.38,-1.81l-3.71,-36.859l-0.6,-0l0.68,36.629c-0.03,0.734 0.165,1.46 0.56,2.08Z" style="fill:#9f3944;fill-rule:nonzero;"/></g></g><g><path d="M87.295,294.618l3.17,0l-0.09,20.93l-3.08,0l-0,-20.93Zm5,20.89c-0.326,-0.208 -0.6,-0.489 -0.8,-0.82c-0.384,-0.602 -0.719,-1.234 -1,-1.89l-0.15,0l-0,-1.35c-0.008,0.241 0.058,0.478 0.19,0.68c0.1,0.183 0.292,0.296 0.5,0.296c0.208,0 0.4,-0.113 0.5,-0.296c0.131,-0.234 0.19,-0.502 0.17,-0.77l-0,-8.68c0.018,-0.264 -0.041,-0.528 -0.17,-0.76c-0.159,-0.262 -0.5,-0.355 -0.77,-0.21c-0.084,0.054 -0.156,0.126 -0.21,0.21c-0.131,0.199 -0.197,0.433 -0.19,0.67l-0,-1.34l0.2,0c0.291,-0.651 0.625,-1.282 1,-1.89c0.181,-0.329 0.438,-0.61 0.75,-0.82c0.253,-0.132 0.535,-0.197 0.82,-0.19c0.437,0.012 0.848,0.217 1.12,0.56c0.371,0.579 0.537,1.266 0.47,1.95l-0,12.36c0.067,0.684 -0.099,1.371 -0.47,1.95c-0.272,0.343 -0.683,0.548 -1.12,0.56c-0.284,-0.005 -0.562,-0.081 -0.81,-0.22l-0.03,0Z" style="fill:#9f3944;fill-rule:nonzero;"/>
    <path d="M98.785,317.098c0.076,0.216 0.281,0.36 0.51,0.36c0.212,0.002 0.412,-0.103 0.53,-0.28c0.14,-0.194 0.211,-0.43 0.2,-0.67l-0,-4.13l-0.26,0l-0.54,0.12l-0.18,-0.14l-2.23,1.85l-0.11,0c-0.407,-0.128 -0.734,-0.433 -0.89,-0.83c-0.183,-0.436 -0.268,-0.907 -0.25,-1.38l-0,-13.49l3.19,0l-0,12.28c-0.012,0.265 0.05,0.528 0.18,0.76c0.096,0.178 0.282,0.29 0.484,0.29c0.16,-0 0.311,-0.07 0.416,-0.19c0.127,-0.116 0.211,-0.271 0.24,-0.44l0.07,-12.68l3,0l-0,17.47c0.052,0.982 -0.234,1.953 -0.81,2.75c-0.795,0.773 -1.901,1.141 -3,1c-0.824,0.061 -1.648,-0.131 -2.36,-0.55c-0.493,-0.353 -0.772,-0.935 -0.74,-1.54c-0.013,-0.451 0.076,-0.898 0.26,-1.31c0.218,-0.423 0.491,-0.816 0.81,-1.17l0.24,-0.34l1.86,-2.53l0.53,0l-1.12,3.69c-0.124,0.355 -0.135,0.739 -0.03,1.1Z"
          style="fill:#9f3944;fill-rule:nonzero;"/>
    <path d="M109.475,294.618l6.7,0l-0,2.69l-3.53,0l-0,15.69l3.49,0l-0,2.62l-6.7,0l0.04,-21Zm1.5,9.09l5,0l-0,1.8l-4.91,0l-0.09,-1.8Z"
          style="fill:#9f3944;fill-rule:nonzero;"/>
    <path d="M116.645,312.408l-0,-4.72l4.58,6l0.15,-0.1l-3.89,-9.47c-0.41,-1 -0.68,-1.71 -0.78,-2.06c-0.101,-0.364 -0.149,-0.742 -0.14,-1.12c-0.046,-0.77 0.246,-1.523 0.8,-2.06c0.553,-0.481 1.267,-0.738 2,-0.72c0.597,-0.009 1.188,0.128 1.72,0.4c0.575,0.32 1.063,0.777 1.42,1.33l1.43,2.09l-0,4.79l-4.55,-6.3l-0.14,0.06l3.65,8.93c0.44,1.12 0.71,1.84 0.85,2.31c0.107,0.388 0.171,0.788 0.19,1.19c0.043,0.789 -0.283,1.554 -0.88,2.07c-0.6,0.498 -1.361,0.762 -2.14,0.74c-0.503,0.006 -1.001,-0.096 -1.46,-0.3c-0.44,-0.192 -0.825,-0.491 -1.12,-0.87l-1.69,-2.19Z"
          style="fill:#9f3944;fill-rule:nonzero;"/>
    <path d="M133.315,307.808l1.26,-8.82c0.128,-0.499 0.072,-1.029 -0.16,-1.49c-0.227,-0.271 -0.568,-0.419 -0.92,-0.4l-0.33,0l-0,18.45l-3.14,0l-0,-20.93l4.75,0c0.877,-0.07 1.748,0.211 2.42,0.78c0.574,0.539 0.881,1.304 0.84,2.09c-0.002,0.551 -0.079,1.1 -0.23,1.63c-0.19,0.647 -0.421,1.282 -0.69,1.9l-4.1,9.63l-0.29,-2.84l0.59,0Z"
          style="fill:#9f3944;fill-rule:nonzero;"/>
    <path d="M141.645,294.618l-0.14,3.19l-3,0l-0.16,-3.19l3.3,0Zm-0,3.89l-0,17l-3,0l-0,-17l3,0Z"
          style="fill:#9f3944;fill-rule:nonzero;"/>
    <path d="M142.525,298.508l3.12,0l-0,17l-3.09,0l-0.03,-17Zm4.25,3.35c-0.106,-0.179 -0.302,-0.287 -0.51,-0.28c-0.196,0.003 -0.374,0.115 -0.46,0.29c-0.14,0.191 -0.207,0.425 -0.19,0.66l-0.31,-1.34l0.54,0c0.291,-0.654 0.625,-1.289 1,-1.9c0.188,-0.318 0.436,-0.596 0.729,-0.82c0.255,-0.128 0.536,-0.193 0.82,-0.19c0.478,0.001 0.932,0.205 1.25,0.56c0.41,0.582 0.591,1.294 0.51,2l0,14.71l-3.199,0l-0,-12.87c0.01,-0.271 -0.052,-0.541 -0.18,-0.78l-0,-0.04Z"
          style="fill:#9f3944;fill-rule:nonzero;"/>
    <path d="M150.904,312.408l0,-4.72l4.57,6l0.15,-0.1l-3.88,-9.47c-0.299,-0.671 -0.56,-1.359 -0.78,-2.06c-0.109,-0.363 -0.159,-0.741 -0.15,-1.12c-0.04,-0.769 0.251,-1.52 0.8,-2.06c0.553,-0.482 1.267,-0.739 2,-0.72c0.598,-0.009 1.188,0.128 1.72,0.4c0.58,0.315 1.068,0.773 1.42,1.33l1.44,2.09l0,4.79l-4.54,-6.3l-0.16,0.06l3.65,8.93c0.326,0.754 0.61,1.525 0.85,2.31c0.121,0.386 0.188,0.786 0.2,1.19c0.042,0.788 -0.283,1.553 -0.88,2.07c-0.599,0.498 -1.36,0.762 -2.14,0.74c-0.505,0.002 -1.005,-0.1 -1.47,-0.3c-0.438,-0.19 -0.82,-0.489 -1.11,-0.87l-1.69,-2.19Z"
          style="fill:#9f3944;fill-rule:nonzero;"/></g></g></g></svg>
</template>

<script>
export default {
  name: 'LogoPuntaNegraCompleto',
}
</script>