import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            requiresAuth: true,
            layout: 'web-layout'
        }
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/404',
        meta: {
            requiresAuth: true,
            layout: 'web-layout'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
