<template>

  <div class="home teal-text text-darken-2">

    <div class="container mb-5 mt-4">
      <div class="backgrund-image-placement border-radius-15"
           style="background-image: url(https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/1.webp); aspect-ratio: 15/6">
      </div>
    </div>

    <div class="container main-color-text font-size-18 font-500 mb-5">
      <div class="row mb-5">
        <div class="col-lg-6 offset-lg-3 text-center">
          {{ i18n.home.texto_1 }}
        </div>
      </div>

      <div class="center-align text-center mb-5">
        <a href="https://cartas.sethotels.net/punta-negra-by-es-pins"
           type="button"
           target="_blank"
           class="button white-text color-azul text-decoration-none title-font font-200">
          {{ i18n.buttons.carta }}
        </a>
      </div>

      <div class="row">
        <div class="col-lg-6 offset-lg-3 text-center">
          {{ i18n.home.texto_2 }}
        </div>
      </div>

    </div>

    <div class="container mb-5">
      <div class="border-radius-15 overflow-hidden">
        <img src="https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/2.webp"
             style="width: 100%">
      </div>
    </div>

    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-5">
          <div style="aspect-ratio: 1/1; width: 100%; background-image: url(https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/3.webp)"
               class="backgrund-image-placement"></div>
        </div>
        <div class="col-lg-7 main-color-text">
          <div class="d-flex w-100 h-100 align-items-center justify-content-center">
            <div class="w-75 font-500 pt-5 pb-5">
              <h5 class="title-font font-size-50 mb-4">{{ i18n.home.texto_3 }}</h5>
              <div class="font-size-18 mb-4">
                {{ i18n.home.texto_4 }}
              </div>
              <div class="font-size-18 font-600">
                {{ i18n.home.texto_5 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-5 crema-text">
      <div class="main-color w-100 border-radius-15 p-5 text-center">
        <h3 class="title-font font-size-50 font-200">{{ i18n.home.texto_6 }}</h3>

        <div class="linea-crema m-auto mb-5"></div>

        <div class="row">
          <div class="col-lg-4 mb-3">
            <PillExperiencias :text="i18n.home.texto_7">
              <IconoPuestaSol
                  style="height: 50px"/>
            </PillExperiencias>
          </div>
          <div class="col-lg-4 mb-3">
            <PillExperiencias :text="i18n.home.texto_8">
              <IconoCocktail
                  class="me-2"
                  style="height: 50px"/>
            </PillExperiencias>
          </div>
          <div class="col-lg-4 mb-3">
            <PillExperiencias :text="i18n.home.texto_9">
              <IconoAmbiente
                  style="height: 50px"/>
            </PillExperiencias>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-5 mt-4">
      <div class="backgrund-image-placement border-radius-15"
           style="background-image: url(https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/6.webp); aspect-ratio: 15/6">
      </div>
    </div>

    <div class="container mb-5">
      <div class="m-auto mb-5" style="width: fit-content">
        <LogoPuntaNegraCompleto
            class="mt-4"
            style="height: 200px" />
      </div>
      <div class="text-center main-color-text font-size-20 font-500">
        <div class="mb-3">
          {{ i18n.home.texto_10 }}
        </div>
        <div>
          {{ i18n.home.texto_11 }}
        </div>
      </div>
    </div>

    <div class="container center-align mb-5"
         style="width: 100%">
      <PhotoCarousel
          :images="[
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%201.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%203.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%207.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%209.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%208.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%2010.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%204.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%2011.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%206.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%202.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/punta-negra/galeria/galeria%205.webp',
                    ]"/>
    </div>

    <div class="container mb-5">
      <div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d382.531983360056!2d4.043940312668141!3d39.91328989333704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12be1f44c6199a2d%3A0xa04a8d143d2e3976!2sPunta%20Negra%20by%20Es%20Pins!5e0!3m2!1ses!2ses!4v1726563261105!5m2!1ses!2ses"
            width="100%"
            height="450"
            style="border:0;"
            class="border-radius-15 overflow-hidden"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>

    <div id="geolocal" class="container mb-5">
      <div class="center-align text-center color-azul-text font-size-22">
        <div class="m-auto mb-5"
             style="width: fit-content">
          <LogoPuntaNegraCompleto
              class="mt-4"
              style="height: 150px; margin-right: 30px; margin-left: 25px" />

          <a href="https://espinsmenorca.com/" target="_blank">
            <EsPinsLogo
                class="mt-4"
                style="height: 135px" />
          </a>
        </div>
        <div class="mb-3">
          Urbanización Santo Tomas, S/N, 07749
          <br/>
          Santo Tomás, Menorca, España
        </div>
      </div>
    </div>

  </div>

</template>

<script>

// @ is an alias to /src

import PhotoCarousel from "@/components/PhotoCarousel";
import PillExperiencias from "@/components/PillExperiencias.vue";
import IconoPuestaSol from "@/svg/IconoPuestaSol.vue";
import IconoCocktail from "@/svg/IconoCocktail.vue";
import IconoAmbiente from "@/svg/IconoAmbiente.vue";
import LogoPuntaNegraCompleto from "@/svg/LogoPuntaNegraCompleto.vue";
import {getI18n} from "../locales/locales";
import EsPinsLogo from "@/svg/EsPinsLogo.vue";

export default {
  name: 'HomeView',
  computed: {
    i18n() {
      return getI18n();
    }
  },

  components: {
    EsPinsLogo,
    LogoPuntaNegraCompleto,
    IconoAmbiente,
    IconoCocktail,
    IconoPuestaSol,
    PillExperiencias,
    PhotoCarousel,
  },
}

</script>

<style scoped>
.double-gradient {
  display: grid;
  place-items: center;
  height: 200px;
  width: 200px;
  background: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(251, 251, 251, 0.1) 100%),
  linear-gradient(90deg, rgba(0, 172, 200, 0.3), rgba(141, 90, 205, 0.05));
}
</style>