<template>
  <svg height="100%" viewBox="0 0 159 181"
       style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:10;"><g id="Capa_2"><g id="Longo"><g><path d="M95.665,118.539c-0.437,-0.002 -0.852,-0.192 -1.14,-0.52l-59.437,-67.996c-0.244,-0.276 -0.378,-0.632 -0.378,-1c-0,-0.828 0.681,-1.51 1.509,-1.51c0.003,0 0.006,0 0.009,0l118.874,0c0.003,0 0.005,0 0.008,0c0.829,0 1.51,0.682 1.51,1.51c0,0.368 -0.135,0.724 -0.378,1l-29.719,33.998l-29.718,33.978c-0.282,0.339 -0.7,0.537 -1.14,0.54Zm-56.087,-67.926l27.518,31.448l28.569,32.649l28.578,-32.649l27.519,-31.448l-112.184,0Z" style="fill:#e0dad2;fill-rule:nonzero;stroke:#e0dad2;stroke-width:2.81px;"/><path
      d="M95.675,118.079c-0.421,0.002 -0.823,-0.172 -1.11,-0.48l-6.89,-7.409l-41.158,-46.998c-0.241,-0.277 -0.375,-0.632 -0.375,-1c0,-0.832 0.683,-1.517 1.515,-1.52l96.075,0c0.832,0.003 1.515,0.688 1.515,1.52c0,0.368 -0.133,0.723 -0.375,1l-18.349,20.869l-22.839,26.109l-6.869,7.399c-0.289,0.327 -0.705,0.513 -1.14,0.51Zm-5.76,-9.939l5.76,6.2l5.74,-6.19l22.828,-26.089l16.109,-18.409l-89.355,0l38.918,44.488Z"
      style="fill:#e0dad2;fill-rule:nonzero;stroke:#e0dad2;stroke-width:2.81px;"/><path
      d="M130.873,178.906l-70.416,0c-0.83,-0.006 -1.508,-0.69 -1.508,-1.52c-0,-0.609 0.366,-1.162 0.928,-1.4l34.278,-14.399l-0.1,-45.138c0.005,-0.831 0.688,-1.514 1.52,-1.519c0.834,-0 1.52,0.686 1.52,1.519l0.1,45.138l34.268,14.409c0.561,0.238 0.928,0.791 0.928,1.4c-0,0.833 -0.685,1.519 -1.518,1.52l-0,-0.01Zm-62.877,-3l55.347,0l-27.668,-11.669l-27.679,11.669Z"
      style="fill:#e0dad2;fill-rule:nonzero;stroke:#e0dad2;stroke-width:2.81px;"/><path
      d="M76.466,63.652c-0.58,-0.001 -1.11,-0.336 -1.36,-0.86l-16.619,-34.108l-56.167,-24.358c-0.555,-0.241 -0.916,-0.791 -0.916,-1.395c0,-0.835 0.687,-1.521 1.521,-1.521c0.208,0 0.414,0.043 0.605,0.126l56.677,24.588c0.333,0.144 0.603,0.403 0.76,0.73l16.859,34.608c0.102,0.209 0.155,0.438 0.155,0.67c0,0.833 -0.683,1.518 -1.515,1.52Z"
      style="fill:#e0dad2;fill-rule:nonzero;stroke:#e0dad2;stroke-width:2.81px;"/><path
      d="M79.346,78.002c-0.004,-0 -0.007,-0 -0.01,-0c-3.006,-0 -5.48,-2.474 -5.48,-5.48c0,-3.006 2.474,-5.48 5.48,-5.48c3.006,0 5.479,2.474 5.479,5.48c-0.005,3 -2.469,5.469 -5.469,5.48Zm-0,-7.91c-1.339,-0 -2.44,1.101 -2.44,2.44c-0,1.338 1.101,2.44 2.44,2.44c1.338,-0 2.44,-1.102 2.44,-2.44c-0,-0.003 -0,-0.007 -0,-0.01c-0,-0.013 -0,-0.027 -0,-0.04c-0,-1.333 -1.097,-2.43 -2.43,-2.43c-0.003,-0 -0.007,-0 -0.01,-0l-0,0.04Z"
      style="fill:#e0dad2;fill-rule:nonzero;"/><path
      d="M106.214,77.282c-0.003,-0 -0.006,-0 -0.01,-0c-3.006,-0 -5.479,-2.474 -5.479,-5.48c-0,-3.006 2.473,-5.48 5.479,-5.48c3.006,0 5.48,2.474 5.48,5.48c-0.005,3 -2.469,5.469 -5.47,5.48Zm0,-7.91c-1.338,0 -2.44,1.101 -2.44,2.44c0,1.338 1.102,2.44 2.44,2.44c1.339,-0 2.44,-1.102 2.44,-2.44l0,-0.01c0,-1.333 -1.097,-2.43 -2.43,-2.43c-0.003,0 -0.006,0 -0.01,0Z"
      style="fill:#e0dad2;fill-rule:nonzero;"/><path
      d="M94.755,94.171c-2.997,-0.006 -5.46,-2.473 -5.46,-5.47c0,-3.001 2.469,-5.47 5.47,-5.47c3.001,0 5.47,2.469 5.47,5.47c-0,0.003 -0,0.007 -0,0.01c-0.006,2.997 -2.473,5.46 -5.47,5.46c-0.003,-0 -0.007,-0 -0.01,-0Zm-0,-7.9c-1.339,0 -2.44,1.102 -2.44,2.44c0,1.338 1.101,2.44 2.44,2.44c1.338,-0 2.44,-1.102 2.44,-2.44c-0,-0.003 -0,-0.007 -0,-0.01c-0,-1.333 -1.097,-2.43 -2.43,-2.43c-0.003,0 -0.007,0 -0.01,0Z"
      style="fill:#e0dad2;fill-rule:nonzero;"/></g></g></g></svg>
</template>

<script>
    export default {
        name: 'IconoCocktail',
    }
</script>