<template>
  <div class="d-flex justify-content-center align-items-center crema border-radius-15 p-2"
       style="border-radius: 20px">
    <div class="me-3 w-100 main-color-text font-size-20 font-500">
      {{ text }}
    </div>
    <div class="main-color p-3"
         style="border-radius: 12px">
      <slot>
        <IconoPuestaSol
            style="height: 50px"/>
      </slot>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import IconoPuestaSol from "@/svg/IconoPuestaSol.vue";

export default {
  name: 'PillExperiencias',

  props: ['text'],

  components: {IconoPuestaSol}
}
</script>
