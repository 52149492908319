export const toggleNav = () => {
    let left = document.getElementById("sideNav").style.left;
    if (left == '0px') {
        document.getElementById("sideNav").style.left = "-310px";
    } else {
        document.getElementById("sideNav").style.left = "0px";
    }
}

export const hideNav = () => {
    document.getElementById("sideNav").style.left = "-310px";
}

/* --- */

const DATE_UNITS = { // in seconds
    year: 31536000,
    month: 2629800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
}

const languageCode = 'es' // English
const rtf = new Intl.RelativeTimeFormat(
    languageCode,
    {numeric: 'auto'}
)

export const getRelativeTime = (timestamp) => {
    const from = new Date(timestamp).getTime()
    const now = new Date().getTime()

    const elapsed = (from - now) / 1000

    for (const unit in DATE_UNITS) {
        if (Math.abs(elapsed) > DATE_UNITS[unit]) {
            return rtf.format(
                Math.floor(elapsed / DATE_UNITS[unit]),
                unit
            )
        }
    }
    // if less than one second, then is now
    return rtf.format(0, 'second')
}

/* --- */

export const getActualTime = (date) => {
    const currentDate = date;
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}