<template>

  <div class="crema p-3"
       id="sideNav"
       style="position: fixed; height: 100vh; width: 300px; border-radius: 0px 20px 20px 0px; z-index: 999">
    <div class="clearfi mb-4">
      <i @click="toggleNavigation"
         class="fa-solid fa-xmark float-end font-size-30 pe-1 main-color-text text-darken-2 cursor-pointer"></i>
    </div>

    <div class="clearfix">
      <div class="mb-4 d-flex align-items-center justify-content-center">
        <router-link to="/"
                     class="text-decoration-none color-text font-size-30 font-400 text-uppercase text-gradient w-50">
            <LogoPuntaNegraCompleto
                style="width: 100%" />
        </router-link>
      </div>
      <div class="mb-2">
        <a href="#carta" @click="toggleNavigation" class="color-azul-text me-4 text-decoration-none text-uppercase">
          {{ i18n.navbar.carta }}
        </a>
      </div>
      <div class="mb-2">
        <a href="#geolocal" @click="toggleNavigation" class="color-azul-text me-4 text-decoration-none text-uppercase">
          {{ i18n.navbar.donde_estamos }}
        </a>
      </div>

      <div class="mb-2">
        <div class="linea-roja"></div>
      </div>

      <div>
        <a href="/" @click="setLocale('es')" class="color-azul-text me-1 text-decoration-none font-600">
          ES
        </a>

        <a href="/" @click="setLocale('en')" class="color-azul-text me-1 text-decoration-none font-600">
          EN
        </a>

        <a href="/" @click="setLocale('ca')" class="color-azul-text me-3 text-decoration-none font-600">
          CA
        </a>
      </div>
    </div>
  </div>

  <div class="teal-text color-text p-3 font-size-16 font-500">
    <div class="container clearfix" style="line-height: 45px">
      <router-link to="/"
                   class="float-start text-decoration-none color-azul-text font-size-30 font-400 text-uppercase text-gradient">
        <LogoPuntaNegra
            style="height: 70px"
            class="me-4"/>
        <TextoPuntaNegra
            style="height: 45px; width: fit-content"
            class="ms-1"/>
      </router-link>

      <div class="float-end main-color-text"
           style="line-height: 70px">
        <!-- Menu Desktop -->
        <div class="d-none d-md-block">
          <a href="/"
             @click="setLocale('es')"
             class="color-azul-text me-1 text-decoration-none font-600">
            ES
          </a>

          <a href="/"
             @click="setLocale('en')"
             class="color-azul-text me-1 text-decoration-none font-600">
            EN
          </a>

          <a href="/"
             @click="setLocale('ca')"
             class="color-azul-text me-3 text-decoration-none font-600">
            CA
          </a>
        </div>

        <div class="d-block d-md-none">
          <IconMenu2
              @click="toggleNavigation"
              class="cursor-pointer"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {IconMenu2} from '@tabler/icons-vue';
import {toggleNav} from "@/functions";
import LogoPuntaNegra from "@/svg/LogoPuntaNegra.vue";
import TextoPuntaNegra from "@/svg/TextoPuntaNegra.vue";
import LogoPuntaNegraCompleto from "@/svg/LogoPuntaNegraCompleto.vue";

export default {

  props: ['auth'],

  name: 'NavBar',

  components: {
    LogoPuntaNegraCompleto,
    TextoPuntaNegra,
    LogoPuntaNegra,
    IconMenu2,
  },

  methods: {
    setLocale(locale) {
      localStorage.locale = locale;
    },
    toggleNavigation() {
      toggleNav();
    }
  },

  mounted() {

  }
}
</script>
