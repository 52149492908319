export const i18n = {
    en: require('@/locales/en.json'),
    es: require('@/locales/es.json'),
    ca: require('@/locales/ca.json'),
}

export function getI18n(){

    let userLocale =
        navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;

    if (userLocale.includes('-')){
        userLocale = userLocale.split('-')[0];
    }

    let locale = localStorage.locale;

    if (locale === undefined){
        locale = userLocale;
    }

    if (i18n[locale] !== undefined){
        return i18n[locale]
    }else{
        return i18n.es
    }

}

