<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 135">
        <g id="Capa_2" data-name="Capa 2">
            <g id="Graphic_Elements" data-name="Graphic Elements">
                <path class="cls-1"
                      d="M135,67.5a67.5,67.5,0,1,0-78,66.68V87H39.81V67.5H57V52.63C57,35.71,67,26.37,82.45,26.37a103.77,103.77,0,0,1,15.11,1.32V44.3H89.05c-8.39,0-11,5.2-11,10.54V67.5H96.77L93.77,87H78.05v47.17A67.51,67.51,0,0,0,135,67.5Z"/>
                <path class="cls-2"
                      d="M93.77,87l3-19.51H78.05V54.84c0-5.34,2.61-10.54,11-10.54h8.51V27.69a103.77,103.77,0,0,0-15.11-1.32C67,26.37,57,35.71,57,52.63V67.5H39.81V87H57v47.17a68.28,68.28,0,0,0,21.1,0V87Z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'FacebookLogo',
    }
</script>

<style scoped>
    .cls-1 {
        fill: #fff;
    }

    .cls-2 {
        fill: none;
    }
</style>
