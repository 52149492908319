<template>
  <svg height="100%"
       viewBox="0 0 207 188"
       style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="Capa_2"><g id="Longo"><g><path d="M100.992,72.74c2.643,-0.031 4.816,-2.178 4.88,-4.819l-0,-63.086c-0,-2.677 -2.203,-4.879 -4.88,-4.879c-2.677,-0 -4.879,2.202 -4.879,4.879l-0,63.086c0.36,2.589 2.239,4.819 4.879,4.819Z" style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M188.446,39.003l-45.047,44.496c-0.896,0.88 -1.402,2.084 -1.402,3.34c0,1.256 0.506,2.46 1.402,3.34c1.856,1.849 4.903,1.849 6.759,-0l45.047,-44.527c0.901,-0.877 1.41,-2.082 1.41,-3.34c0,-1.257 -0.509,-2.462 -1.41,-3.339c-1.865,-1.841 -4.911,-1.827 -6.759,0.03Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M51.806,90.179c1.856,1.849 4.903,1.849 6.759,-0c0.897,-0.88 1.402,-2.084 1.402,-3.34c0,-1.256 -0.505,-2.46 -1.402,-3.34l-45.046,-44.496c-1.857,-1.849 -4.903,-1.849 -6.76,-0c-0.901,0.877 -1.41,2.082 -1.41,3.339c0,1.258 0.509,2.463 1.41,3.34l45.047,44.497Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M123.131,76.82c0.546,0.22 1.129,0.334 1.719,0.334c1.891,-0 3.598,-1.17 4.28,-2.934l11.629,-29.308c0.218,-0.536 0.329,-1.11 0.329,-1.689c0,-1.879 -1.176,-3.569 -2.939,-4.22c-0.546,-0.221 -1.129,-0.334 -1.719,-0.334c-1.891,-0 -3.598,1.169 -4.28,2.934l-11.649,29.277c-0.223,0.543 -0.337,1.123 -0.337,1.709c-0,1.89 1.19,3.587 2.967,4.231Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M187.696,89.809l-29.278,12.619c-2.209,1.058 -3.331,3.592 -2.63,5.939c1.101,2.197 3.646,3.296 6,2.59l29.277,-12.609c2.209,-1.058 3.331,-3.592 2.63,-5.939c-0.71,-2.6 -3.37,-3.71 -5.999,-2.6Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M13.139,92.409c-0.546,-0.221 -1.13,-0.334 -1.719,-0.334c-1.892,-0 -3.598,1.169 -4.281,2.933c-1.139,2.22 0.37,5.19 2.62,5.99l29.658,11.499c0.546,0.221 1.13,0.334 1.719,0.334c1.892,0 3.598,-1.169 4.281,-2.934c1.119,-2.22 -0.38,-5.189 -2.63,-5.939l-29.648,-11.549Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M70.995,75c0.68,1.707 2.286,2.877 4.119,3c0.062,0.002 0.123,0.004 0.184,0.004c2.408,-0 4.39,-1.982 4.39,-4.39c-0,-0.667 -0.152,-1.325 -0.444,-1.924l-12.799,-29.008c-1.104,-2.194 -3.644,-3.295 -6,-2.599c-2.199,1.057 -3.319,3.578 -2.629,5.919l13.179,28.998Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M201.984,128.666l-53.325,-0l-0,-1c-0,-24.468 -20.999,-43.767 -47.677,-43.767c-26.678,0 -48.426,19.999 -48.426,44.457l-0,0.34l-47.676,-0c-2.469,-0 -4.5,2.031 -4.5,4.499c-0,2.469 2.031,4.5 4.5,4.5l197.104,0c2.55,0.058 4.721,-1.934 4.88,-4.48c-0.253,-2.514 -2.354,-4.472 -4.88,-4.549Zm-139.989,-0c0,-19.999 17.639,-36.177 38.997,-36.177c21.359,-0 38.287,15.508 38.287,35.147l0,1l-77.284,0.03Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M194.455,149.924l-4.13,3c-5.999,4.46 -14.639,5.2 -20.998,1.49l-2.62,-1.49c-9.423,-5.195 -20.987,-4.763 -29.998,1.12l-1.119,0.37c-5.903,3.712 -13.324,4.121 -19.599,1.08l-9.769,-4.82c-8.008,-4.087 -17.51,-4.087 -25.518,0l-10.129,4.82c-6.263,2.981 -13.634,2.562 -19.519,-1.11c-9.682,-6.298 -22.225,-6.298 -31.907,-0l-11.64,7.789c-1.256,0.797 -2.02,2.185 -2.02,3.673c0,0.953 0.313,1.879 0.891,2.637c0.822,1.246 2.219,1.997 3.712,1.997c0.962,0 1.898,-0.311 2.667,-0.887l11.639,-7.79c6.424,-4.44 14.974,-4.44 21.398,0c8.652,5.587 19.615,6.288 28.908,1.85l10.139,-4.819c5.456,-2.599 11.803,-2.599 17.259,-0l10.139,4.819c9.296,4.427 20.252,3.726 28.908,-1.85l1.13,-0.74c6.038,-4.127 13.945,-4.415 20.269,-0.74l2.629,1.48c9.954,5.552 22.275,4.845 31.528,-1.81l4.13,-2.999c1.289,-0.881 2.062,-2.345 2.062,-3.906c0,-1.005 -0.319,-1.983 -0.913,-2.794c-2.649,-1.48 -5.659,-1.85 -7.529,-0.37Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/><path
      d="M194.455,166.993l-4.13,3c-5.999,4.459 -14.639,5.199 -20.998,1.49l-2.65,-1.49c-9.423,-5.195 -20.987,-4.764 -29.998,1.12l-1.119,0.74c-5.896,3.7 -13.302,4.109 -19.569,1.079l-10.139,-4.819c-8.014,-4.076 -17.514,-4.076 -25.528,-0l-9.759,4.819c-6.263,2.982 -13.634,2.563 -19.519,-1.109c-9.682,-6.299 -22.225,-6.299 -31.907,-0l-11.63,7.789c-1.256,0.797 -2.02,2.185 -2.02,3.673c0,0.952 0.313,1.879 0.891,2.636c0.825,1.231 2.213,1.971 3.695,1.971c0.969,0 1.911,-0.316 2.684,-0.901l11.639,-7.789c6.424,-4.44 14.974,-4.44 21.398,-0c8.653,5.584 19.612,6.289 28.908,1.86l10.139,-4.83c5.458,-2.588 11.801,-2.588 17.259,0l10.139,4.83c9.299,4.418 20.252,3.713 28.908,-1.86l1.13,-0.74c6.038,-4.127 13.945,-4.416 20.269,-0.74l2.629,1.48c9.962,5.54 22.283,4.817 31.528,-1.85l4.13,-3c1.278,-0.88 2.044,-2.336 2.044,-3.888c-0,-1.004 -0.321,-1.982 -0.915,-2.791c-2.629,-1.79 -5.639,-2.16 -7.509,-0.68Z"
      style="fill:#ded9d1;fill-rule:nonzero;"/></g></g></g></svg>
</template>

<script>
    export default {
        name: 'IconoPuestaSol',
    }
</script>