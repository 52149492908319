<template>
  <svg
       height="100%"
       viewBox="0 0 156 135"
       style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="Capa_2"><g id="Capa_4"><g><path d="M155.443,55.294l-0,2.9c0.014,0.049 0.014,0.101 -0,0.15l-0,0.12c-0.006,0.171 -0.026,0.342 -0.06,0.51c-0.005,0.277 -0.025,0.554 -0.06,0.83c-1.412,15.891 -7.215,31.08 -16.758,43.865c-0.07,0.117 -0.151,0.227 -0.24,0.33c-2.823,3.529 -5.955,6.798 -9.359,9.769l-2.15,0.06l-80.882,3.16c-1.154,-0.012 -2.249,-0.523 -2.999,-1.4l-40.356,-42.596l-0.09,-0.09l-0,-0.09c-0.078,-0.072 -0.149,-0.152 -0.21,-0.24c-0.42,-1.579 -0.78,-3.189 -1.07,-4.809c-0.42,-2.12 -0.72,-4.27 -1,-6.449c0,-0.12 0,-0.21 -0.06,-0.33c-0.06,-0.12 -0.08,0.01 -0.14,0.01l0,-1.51c0.013,-0.029 0.013,-0.062 0,-0.09c-0.013,-0.029 0,-0 0,-0.06l0,-2.18c0,-2.46 0,-4.879 0.12,-7.299c0.06,-2.75 0.18,-5.49 0.33,-8.209c0.27,-1.06 0.701,-2.072 1.28,-3c0.699,-1.442 2.112,-2.41 3.71,-2.54l144.764,0c1.325,0.075 2.537,0.778 3.26,1.89c0.634,0.841 1.127,1.78 1.46,2.78l-0,0.06c0.27,4.479 0.45,8.999 0.48,13.598c0.025,0.286 0.035,0.573 0.03,0.86Z" style="fill:#a14a48;fill-opacity:0.6;fill-rule:nonzero;"/><ellipse
      cx="120.287" cy="13.479" rx="13.849" ry="13.479" style="fill:#9f3943;"/><path
      d="M0.009,60.994c-0.015,0.159 -0.015,0.32 0,0.48c-0.016,-0.16 -0.016,-0.321 0,-0.48Z"
      style="fill:#b3b0aa;fill-rule:nonzero;"/><path
      d="M153.113,62.083c-0.27,2.57 -0.63,5.14 -1.14,7.65c-3.295,17.073 -12.154,32.584 -25.187,44.095c-13.033,11.511 -0,0 -0,0c-11.915,10.624 -26.9,17.197 -42.786,18.768c-1.08,0.09 -2.179,0.18 -3.289,0.24c-0.68,0 -1.34,0.06 -2.06,0.06l-2.06,0c-1,0 -2,0 -3,-0.09c-0.66,0 -1.309,0 -1.999,-0.09c-1,-0.06 -1.91,-0.12 -2.87,-0.21c-1.11,-0.12 -2.18,-0.24 -3.26,-0.42c-0.05,-0.003 -0.1,-0.003 -0.15,0c-16.36,-2.489 -31.465,-10.266 -42.995,-22.138c-0.74,-0.779 -1.49,-1.559 -2.21,-2.359c-0.72,-0.8 -1.39,-1.6 -2.1,-2.42c0,-0.06 -0.09,-0.09 -0.12,-0.15c-0.03,-0.06 -0.15,-0.18 -0.24,-0.27c-7.144,-9.305 -12.313,-19.973 -15.188,-31.347c-0.09,-0.3 -0.15,-0.57 -0.21,-0.87c-0.42,-1.579 -0.78,-3.189 -1.07,-4.809c-0.42,-2.12 -0.72,-4.27 -1,-6.449c0,-0.12 0,-0.21 -0.06,-0.33c-0.06,-0.12 0,-0 0.06,-0c0.18,-0.15 0.42,-0.27 0.72,-0.48l0.24,-0.15c7.419,-4.69 38.456,-21.508 73.862,-0.77l0.23,0.15c0.9,0.5 1.83,1.1 2.72,1.67c38.946,28.977 70.043,5.109 75.162,0.719Z"
      style="fill:#833443;fill-rule:nonzero;"/><path
      d="M155.443,58.494c0.014,0.049 0.014,0.101 -0,0.15c-0.005,0.211 -0.025,0.421 -0.06,0.63c-0,0.18 -0,0.36 -0.06,0.53c-1.412,15.891 -7.215,31.08 -16.758,43.865c-0.07,0.117 -0.151,0.227 -0.24,0.33c-3.107,4.128 -6.599,7.952 -10.429,11.419c-11.689,10.671 -26.466,17.366 -42.196,19.118c-1,0.09 -1.94,0.15 -2.9,0.24c-0.679,0.06 -1.369,0.12 -2.089,0.12c-0.72,-0 -1.34,0.06 -2.06,0.06l-1.37,-0c-1.26,-0 -2.51,-0 -3.74,-0.09c-1.229,-0.09 -2.269,-0.21 -3.379,-0.3c-20.878,-2.12 -39.376,-13.089 -52.155,-29.397c0,-0.06 -0.09,-0.09 -0.12,-0.15c-0.03,-0.06 -0.15,-0.18 -0.24,-0.27c-7.144,-9.305 -12.313,-19.973 -15.188,-31.347c-0.09,-0.3 -0.15,-0.57 -0.21,-0.87c-0.42,-1.579 -0.78,-3.189 -1.07,-4.809c-0.42,-2.12 -0.72,-4.27 -1,-6.449c0,-0.12 0,-0.21 -0.06,-0.33c-0.06,-0.3 -0.06,-0.63 -0.12,-1l0,-0.35l0.01,-0l0.93,0.77c0.06,-0 0.15,0.12 0.24,0.18c7.709,6.449 37.166,26.817 73.822,-0.68l0.23,-0.17c0.399,-0.253 0.78,-0.534 1.14,-0.84c38.996,-26.267 73.502,-4.27 78.852,-0.51c0.06,0.06 0.12,0.06 0.18,0.12c0.06,0.06 0.04,-0 0.04,0.03Z"
      style="fill:#9f3943;fill-rule:nonzero;"/></g></g></g></svg>
</template>

<script>
    export default {
        name: 'LogoPuntaNegra',
    }
</script>